import {get} from 'lodash'
import mg from './mg.yml'
import sk from './sk.yml'

// Parity with backend/src/api/landing-page/content-types/landing-page/schema.json
export const SK = 'SK'
export const MG = 'MG'

const translations = {
  [SK]: sk,
  [MG]: mg,
}

export const t = (lang = SK, key) => {
  const translation = get(translations, [lang, key], undefined)
  if (!translation) {
    return key
  }
  return translation
}
